import { BannerSection } from 'components/BannerSection';
import { Services } from 'components/Services';
import dynamic from 'next/dynamic';
import React from 'react';

import { cx } from '@emotion/css';
import { MarqueeView } from '@modules/layout/marquee/MarqueeView';
import { FAQsView } from '@modules/shared/FAQs/FAQsView';

import { AnimatedOurStory } from './AnimatedOurStory';
import styles from './HomePage.module.scss';

const DynamicWhyChooseUs = dynamic(() => import('components/WhyChooseUs'), {
  ssr: true,
});
const DynamicReviews = dynamic(() => import('components/TrustPilot'), {
  ssr: true,
});
export const HomePage = () => {
  return (
    <div className={styles.body}>
      <div className={cx(styles.sec, styles.root, styles.root_home)}>
        <BannerSection styles={styles} />

        <MarqueeView isBackgroundColorRequired={true} />

        <Services styles={styles} />
        <DynamicWhyChooseUs styles={styles} />
        <DynamicReviews styles={styles} />
      </div>
      <FAQsView faqItems={faqItems} />
      <AnimatedOurStory styles={styles} />
    </div>
  );
};

const faqItems = [
  {
    title: 'How can I contact you?',
    details:
      'Our team is always happy to help via email request@qeeper.co.uk, or phone 0333 335 5172. Our office hours are 8am - 10pm Monday to Friday and 9am - 6pm on weekends and bank holidays.',
  },
  {
    title: 'Which areas do you cover? ',
    details:
      'At the moment, we cover London, Brighton and Edinburgh. We’ll have some exciting news about new cities soon!',
  },
  {
    title: 'I’m a tradesperson. How can I join?',
    details:
      'Drop us an email to trades@qeeper.co.uk and the Qeeper team will be in touch. We are a friendly bunch with high standards and are always keen to welcome onboard skilled and reliable people who share our ethos and values.',
  },
];
