import React, { useState } from 'react';

import { cx } from '@emotion/css';
import { Article } from '@modules/craft';

import styles from './FAQsView.module.scss';

export type FAQItem = {
  title: string;
  details: string;
};

type Props = {
  faqItems: FAQItem[];
  isBackgroundColorRequired?: boolean;
};

export const FAQsView = (props: Props) => {
  const { faqItems, isBackgroundColorRequired } = props;

  return (
    <section
      className={
        isBackgroundColorRequired
          ? styles.faq_sections_color
          : styles.faq_sections
      }>
      <div className={styles.container}>
        <div className={styles.faq_sections_inner}>
          <h3 className={styles.faq_title}>Frequently Asked Questions</h3>
          <div className={styles.faq_wrap}>
            <div className={styles.faq_listing}>
              <div className={styles.faq_item_wrpr}>
                {faqItems.map((faqItem, index: number) => (
                  <FAQItem
                    key={index}
                    styles={styles}
                    title={faqItem.title}
                    details={faqItem.details}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

type FAQItemProps = {
  styles: any;
  title: string;
  details: string;
};
const FAQItem = (props: FAQItemProps) => {
  const { styles, title, details } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((open) => !open);
  return (
    <div
      className={
        open ? cx(styles.faq_item, styles.faqItem_active) : styles.faq_item
      }
      onClick={toggleOpen}>
      <div
        className={
          open
            ? cx(styles.faq_item_header, styles.faqHeader_active)
            : styles.faq_item_header
        }>
        <p
          className={styles.faq_item_text}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <span
          className={
            open ? cx(styles.faq_icon, styles.faq_icon_active) : styles.faq_icon
          }></span>
      </div>
      <Article>
        <div
          className={
            open
              ? cx(styles.faq_item_content, styles.faq_item_content_active)
              : styles.faq_item_content
          }
          dangerouslySetInnerHTML={{
            __html: details,
          }}
        />
      </Article>
    </div>
  );
};
