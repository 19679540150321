import { NextSeo } from 'next-seo';
import Head from 'next/head';
import React from 'react';

import { HomePage } from '@modules/home/HomePage';

const SEO_KEYWORDS =
  'Property maintenance, Boiler repair, Emergency plumber, Gas safety checks, Electrical repairs, Appliance repair, Heating services, Drainage specialists, Handyman services, Locksmith London, Furniture assembly, Landlord certificates, Home repairs, Gas engineers, Lighting installation, Blocked drains, Emergency electrician, PAT testing, Fuse box repairs, London handyman';

const URL = 'https://www.qeeper.co.uk';

const TITLE = 'Emergency Boiler Repair London | Plumber London | Qeeper';
const DESC =
  'Need an Expert Heating Engineer, Plumber, Electrician, Handyman, Appliance Engineer or Locksmith in London? Contact Qeeper to book your fast and reliable home repair today!';

const Home = () => {
  return (
    <>
      <Head>
        <meta name="keywords" content={SEO_KEYWORDS} />
        <link rel="canonical" key="canonical" href={URL} />
        <link rel="alternate" hrefLang="en" href={URL} />
      </Head>
      <NextSeo
        title={TITLE}
        description={DESC}
        canonical={URL}
        openGraph={{
          url: URL,
          title: TITLE,
          description: DESC,
        }}
      />
      <HomePage />
    </>
  );
};
export default Home;
