import React from 'react';

import styles from './MarqueeView.module.scss';

type MarqueeViewProps = {
  isBackgroundColorRequired?: boolean;
};
export const MarqueeView = (props: MarqueeViewProps) => {
  const { isBackgroundColorRequired } = props;
  return (
    <div
      className={
        isBackgroundColorRequired
          ? styles.trusted_by_industry_color
          : styles.trusted_by_industry
      }>
      <p className={styles.trusted_by_industry_p}>
        Trusted by Industry Leaders
      </p>
      <div className={styles.marquee_header}>
        <div className={styles.marquee_block}>
          <div className={styles.marquee}>
            <div className={styles.marquee__inner}>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-1.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-2.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-3.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-4.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-5.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-6.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-7.svg" />
              </div>
            </div>

            <div className={styles.marquee__inner} aria-hidden="true">
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-1.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-2.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-3.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-4.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-5.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-6.svg" />
              </div>
              <div className={styles.mq_data}>
                <img src="/images/marquee/tr-7.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
