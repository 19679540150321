import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

const Lottie = dynamic(() => import('react-lottie-player'), {
  ssr: false,
});
interface LottieState {
  story?: any;
}

export const AnimatedOurStory = ({ styles }: any) => {
  const [animationData, setAnimationData] = useState<LottieState>();
  useEffect(() => {
    import('public/lottie/our-story.json').then((res) =>
      setAnimationData((state) => ({ ...state, story: res.default })),
    );
  }, []);
  return (
    <div className={styles.our_story_img}>
      <Lottie
        animationData={animationData?.story}
        speed={1}
        loop
        style={{ maxWidth: '450px', margin: '0px auto' }}
        play></Lottie>
    </div>
  );
};
